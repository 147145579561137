import {Card, CardMedia} from "@mui/material";
import React from "react";

import taigetos from './../assets/lakon.jpeg';

const PaperHero = () => {

  return (
    <Card square>
      <CardMedia image={taigetos} sx={{paddingTop:{ xs: '75%', sm:'56.25%', md:'42.85%'}}} />
    </Card>
  )
}

export default PaperHero;