import {Container, Grid, Paper, Typography} from "@mui/material";
import React from "react";

import lakon1 from '../assets/lakon-1.jpeg'
import lakon2 from '../assets/lakon-2.jpeg'
import lakon3 from '../assets/lakon-3.jpeg'
import lakon4 from '../assets/lakon-4.jpeg'
import lakon5 from '../assets/lakon-5.jpeg'
import CardAbout from "./CardAbout";

const aboutUs = [
  {img: lakon1, desc: 'Το μέλι μας δεν είναι επεξεργασμένο, δεν είναι φιλτραρισμένο υπό πίεση ούτε παστεριωμένο.'},
  {img: lakon2, desc: 'Είναι το 100% φυσικό αγνό μέλι, μη-παστεριωμένο, χωρίς καμία επεξεργασία. Μόνο έτσι διατηρούνται όλα τα φυσικά θρεπτικά συστατικά και οι ιδιότητες που προσφέρει η φύση.'},
  {img: lakon3, desc: 'Το Μέλι ΛΑΚΩΝ είναι φυσικό, ακατέργαστο μέλι όπως ακριβώς βγαίνει απο την κυψέλη. Γι’ αυτό το λόγο, το γνήσιο αυτό μέλι περιέχει μικρές ποσότητες γύρης, ή κεριού.'},
  {img: lakon4, desc: 'Το μη παστεριωμένο μέλι διατηρεί τις φυσικές, θεραπευτικές ιδιότητές του και το μοναδικό του άρωμα σε αντίθεση με το μέλι μαζικής παραγωγής, το οποίο συχνά θερμαίνεται σε σημαντικά υψηλότερες θερμοκρασίες από αυτές της κυψέλης καθώς και να φιλτράρετε υποπίεση με αποτέλεσμα να αλλοιώνεται η σύνθεση και η ποιότητα του. Όταν το μέλι θερμαίνεται, το λεπτό άρωμα, η μαγιά, τα ένζυμα, οι βιταμίνες και τα μέταλλα έχουν εν μέρει καταστραφεί ή ελαχιστοποιηθεί. Το κατεργασμένο, παστεριωμένο μέλι, χάνει τις αντιοξειδωτικές και αντιβακτηριδιακές του ιδιότητες λόγω της υψηλής θερμοκρασίας της παστερίωσης.'},
  {img: lakon5, desc: 'Το καθαρό, ακατέργαστο, μη παστεριωμένο μέλι που προήλθε από άνθη (Θυμαρίσιο, Ανθέων, ερείκης κλπ) έχει τη φυσική τάση να "κρυσταλλώνει" με την πάροδο του χρόνου ή όταν διατηρείται σε δροσερό – κρύο μέρος. Η “κρυστάλλωση” είναι ένα σημάδι της υψηλής ποιότητας μελιού λόγω των ζυμομυκήτων και δεν επηρεάζει τη διατροφική σύνθεση του, εκτός ίσως από την υφή και το χρώμα'},
]

const PaperAbout = () => {
  return (
    <Paper sx={{py:6}}>
      <Container maxWidth={"md"}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography variant={"h4"} color={"primary"} align={"center"} gutterBottom>Σχετικά με εμάς</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {aboutUs.map((item,index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <CardAbout desc={item.desc} img={item.img} zebra={index % 2 === 0} />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default PaperAbout;