import {Card, CardContent, CardMedia, Typography, Grid} from "@mui/material";
import React from "react";

interface CardAboutProps {
  desc:string,
  img: any,
  zebra: boolean;
};

const CardAbout: React.FC<CardAboutProps> = ({desc,img, zebra}) => {
  return (
    <Card sx={{my:3}}>
      <Grid container spacing={{xs:0,sm:3}} direction={zebra ? 'row' : 'row-reverse'} alignItems={"center"}>
        <Grid item xs={12} sm={4} alignSelf={"stretch"}>
          <CardMedia sx={{height:'100%', minHeight:'268px'}} image={img} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CardContent>
            <Typography color={"common.black"} variant={"body1"}>{desc}</Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CardAbout