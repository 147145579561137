import {
  Container,
  Paper,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardMedia,
  CardContent
} from "@mui/material";
import {Close} from "@mui/icons-material";
import CardProduct from "./CardProduct";
import React from "react";

import meliElatis from "../assets/meli-elatis.jpg";
import melVaniliaElatis from "../assets/meli-vanilia-elatis.jpg";
import meliDasous from "../assets/meli-dasous.jpg";
import meliEreikis from "../assets/meli-erikis.jpg";
import melikastanias from "../assets/meli-kastanias.jpg";
import meliPortokalias from "../assets/meli-portokalias.jpg";
import meliThimarisio from "../assets/meli-thimarisio.jpg";
import meliVelanidias from "../assets/meli-velanidias.jpg";
import giri from "../assets/giri.jpg";
import {useContextProvider} from "./ContextProvider";
import {Product} from "./CardProduct";

const PaperProducts = () => {
  const {state,dispatch} = useContextProvider();
  const products: Array<Product> = [
    {title: 'Μέλι δάσους', img: meliDasous, description: '' },
    {title: 'Μέλι ελλάτης', img: meliElatis, description: '' },
    {title: 'Μέλι βανίλια ελλάτης', img: melVaniliaElatis, description: '' },
    {title: 'Μέλι βελανυδιά', img: meliVelanidias, description: 'Το δρυόμελο έχει ιδιαίτερα σκούρο χρώμα, σχεδόν μαύρο με μεστή καραμελένια γεύση χαμηλής γλυκύτητας. Ως μέλι μελιτώματος κρυσταλλώνει ιδιαίτερα αργά παραμένοντας παχύρρευστο για αρκετά μεγάλο χρονικό διάστημα. Είναι πλούσιο σε αντιοξειδωτικές ιδιότητες και περιέχει σε μεγάλες ποσότητες κάλιο, μαγνήσιο, φώσφορο, σίδηρο και νάτριο. Τα ένζυμα που περιέχει, ενισχύουν το μεταβολισμό και τις λειτουργίες των ζωτικών οργάνων. Βοηθάει στις διαταραχές του στομάχου, στη γρίπη και στην υπερκόπωση. Οι χαλαρωτικές ιδιότητες που έχει βοηθούν όσους έχουν προβλήματα με την αϋπνία, ένα ρόφημα με ζεστό νερό και μια κουταλιά μέλι είναι ότι πρέπει για να χαλαρώσετε πριν τον ύπνο. Δύο κουταλιές μέλι βελανιδιάς περιέχουν 274 mg ασβεστίου, το ίδιο επίπεδο ασβεστίου που υπάρχει σε ένα φλιτζάνι γάλα.' },
    {title: 'Μέλι ερείκης', img: meliEreikis, description: 'Παράγεται από τον Οκτώβριο έως τον Νοέμβριο. Παράγεται από το φυτό ερείκη που είναι καθαρά μελισσοκομικό φυτό και το μέλι της είναι σκοτεινόχρωμο, κάτι που μπορεί να μην αρέσει ιδιαίτερα στον καταναλωτή, έχει όμως υψηλή θρεπτική αξία, κοκκινοχάλκινο χρώμα, γήινο καραμέλας. Κρυσταλλώνει σε 1 με 3 μήνες από την παραγωγή του. Είναι το τελευταίο μέλι της χρονιάς, πριν από τον χειμώνα.' },
    {title: 'Μέλι θυμαρισιο', img: meliThimarisio, description: 'Παράγεται τον Ιούνιο και τον Ιούλιο. Από κάποιους θεωρείται το καλύτερο μέλι. Καλύπτει περίπου το 10% της ελληνικής παραγωγής. Μπορεί να περιέχει επίσης θρούμπι, ρίγανη αγριοδεντρολίβανο, αγριομέντα, φασκομηλιά, τσάι του βουνού κ.ά. Ένα θυμαρίσιο μέλι σπάνια είναι αμιγώς θυμαρίσιο. Πάντως, για να πάρει την ονομασία αυτή πρέπει να περιέχει μέλι από θυμάρι σε συγκεκριμένη αναλογία. Tο θυμαρίσιο μέλι έχει ευχάριστη γεύση, αλλά ορισμένες φορές, λόγω υψηλής συγκέντρωσης σε φρουκτόζη, αφήνει μια αίσθηση καψίματος στο στόμα, έντονα αρωματικό μέλι, χρώμα συνήθως ανοιχτό κεχριμπαρένιο, κρυσταλλώνει σε διάστημα 6-18 μηνών.' },
    {title: 'Μέλι πορτοκαλιάς', img: meliPortokalias, description: '' },
    {title: 'Μέλι καστανιάς', img: melikastanias, description: '' },
    {title: 'Μέλι', img: giri, description: '' },
    {title: 'Βάμα προπόλης', img: giri, description: '' },
    {title: 'Κεραλοιφη', img: giri, description: '' },
    {title: 'Βασιλικός πολτός', img: giri, description: '' },
    {title: 'ΚΗΡΗΘΡΑ', img: giri, description: '' },
    {title: 'LIP BALM', img: giri, description: '' },
  ]
  const handleDialogClose = () => {
    dispatch({type:'SET_DIALOG',payload:false});
  }
  return (
    <Paper  sx={{py:6}}>
      <Container maxWidth={"lg"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={"h4"} color={"primary"} align={"center"} gutterBottom>Τα προϊόντα μας</Typography>
          </Grid>
          {products.map((prd,index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CardProduct product={prd} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <Dialog
        open={state.productDialog}
        onClose={handleDialogClose}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle sx={{display:'flex',justifyContent:"space-between",alignItems: "center"}} >
          <Typography variant={"h4"}>
            {state.product?.title}
          </Typography>
          <IconButton onClick={handleDialogClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Card>
                {state.product && (
                  <CardMedia image={state.product?.img} sx={{paddingTop:'100%'}} />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Card>
                <CardContent>
                  {state.product?.description}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  )
}

export default PaperProducts;