import React, {useReducer, useMemo, Dispatch, createContext, useContext} from "react";
import {Product} from "./CardProduct";

interface ContextProps {
  state: defaultStateInterface,
  dispatch: Dispatch<Actions>;
}

interface defaultStateInterface {
  productDialog: boolean,
  product?: Product,
}

export const defaultState:defaultStateInterface = {
  productDialog: false,
  product: undefined,
}

export type Actions = SetProduct|SetDrawer;

interface SetProduct {
  type: "SET_PRODUCT";
  payload: any;
}
interface SetDrawer {
  type: "SET_DIALOG";
  payload: any;
}

export const reducer = (state: defaultStateInterface, action: Actions) => {
  console.log(action,state);
  switch (action.type) {
    case "SET_PRODUCT":
      return {
        ...state,
        product: action.payload,
      };
    case "SET_DIALOG":
      return {
        ...state,
        productDialog: action.payload,
      };
  }
};

const StateContext = createContext({} as ContextProps);

export const useContextProvider = () => useContext(StateContext);

export interface ContextProviderProps {
  children: JSX.Element
}

const ContextProvider: React.FC<ContextProviderProps> = ({children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export default ContextProvider