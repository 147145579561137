import {
  Container,
  Grid,
  Paper,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Alert,
  AlertColor
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import emailjs from "emailjs-com";

const SERVICE_ID = 'service_lxc4wt9';
const TEMPLATE_ID = 'template_yxo99bi';
const USER_ID = 'user_BXASC6VCHbqP1vZeITGlU';

const PaperContact = () => {
  const [snackbar,setSnackbar] = useState(false)
  const [message,setMessage] = useState('Tο μήνυμα στάλθηκε με επιτυχία')
  const [severity,setSeverity] = useState<AlertColor>('success');
  const [fromName,setFromName] = useState('');
  const [fromEmail,setFromEmail] = useState('');
  const [fromPhone,setFromPhone] = useState('');
  const [fromMessage,setFromMessage] = useState('');

  const handleClose = () => {
    setSnackbar(false)
  }
  const templateParams = {
    from_name: fromName,
    from_email: fromEmail,
    from_phone: fromPhone,
    from_message: fromMessage,
  };
  const handleSendMail = () => {
    emailjs.send(SERVICE_ID,TEMPLATE_ID,templateParams,USER_ID)
      .then(function(_response) {
        setSeverity('success');
        setMessage('Tο μήνυμα στάλθηκε με επιτυχία!');
        setSnackbar(true);
      }, function(_error) {
        setSeverity('error');
        setMessage('Tο μήνυμα δεν στάλθηκε. Παρακαλώ προσπαθήστε αργοτερα.');
        setSnackbar(true);
      });
  }
  const handleChangeFromName = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log(e.target.value)
    setFromName(e.target.value);
  }
  const handleChangeFromEmail = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log(e.target.value)
    setFromEmail(e.target.value);
  }
  const handleChangeFromPhone = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log(e.target.value)
    setFromPhone(e.target.value);
  }
  const handleChangeFromMessage = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // console.log(e.target.value);
    setFromMessage(e.target.value);
  }
  return (
    <Paper sx={{py:6}} id={"contact"}>
      <Container maxWidth={"lg"}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography variant={"h4"} color={"primary"} align={"center"} gutterBottom>Επικοινωνήστε μαζί μας</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField label={"Όνομα"} type={"text"} color={"primary"} required onChange={handleChangeFromName} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label={"Email"} type={"email"} color={"primary"} required onChange={handleChangeFromEmail} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label={"Τηλεφωνο"} type={"tel"} color={"primary"} required onChange={handleChangeFromPhone} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label={"Μυνημα"} color={"primary"} rows={4} required multiline onChange={handleChangeFromMessage} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant={"contained"} size={"large"} color={"primary"} fullWidth onClick={handleSendMail}>ΑΠΟΣΤΟΛΗ</Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Snackbar open={snackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                  {message}
                </Alert>
              </Snackbar>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default PaperContact;