import {Card, CardActionArea, CardContent, CardMedia} from "@mui/material";
import React from "react";
import {useContextProvider} from "./ContextProvider";

export interface Product {
  title: string,
  description: string,
  img: any,
}

interface CardProductProps {
  product: Product
};

const CardProduct: React.FC<CardProductProps> = ({product}) => {
  const {dispatch} = useContextProvider();
  const handleClickCard = () => {
    dispatch({type:'SET_DIALOG',payload: true})
    dispatch({type:'SET_PRODUCT',payload: product})
  }
  return (
    <Card>
      <CardActionArea onClick={() => handleClickCard()}>
        <CardContent>
        <CardMedia sx={{pt:'100%'}} image={product.img}/>
        {/*<CardContent>*/}
        {/*  <Typography color={"common.black"} variant={"h5"} align={"center"}>{title}</Typography>*/}
        {/*</CardContent>*/}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CardProduct