import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline,createTheme,ThemeProvider} from "@mui/material";
import ContextProvider from './components/ContextProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#bb7901',
    },
    secondary: {
      main: '#735d38',
    },
    background: {
      default: '#292824',
      paper: '#c4a181',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html:{
          scrollBehavior: 'smooth',
        },
        body:{
          scrollBehavior: 'smooth',
        },
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        // square: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f3f0eb'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#f3f0eb'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      }
    }
  },

});

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
