import React from 'react';
import {AppBar, Box, Button, Toolbar, IconButton, Typography} from '@mui/material'
import { Facebook, Mail, Phone} from '@mui/icons-material'
import PaperProducts from "./components/PaperProducts";
import PaperHero from "./components/PaperHero";
import PaperContact from "./components/PaperContact";
import PaperAbout from "./components/PaperAbout";

function App() {
  return (
    <>
      <AppBar position={"sticky"} color={"primary"}>
        <Toolbar>
          <Typography component={"h1"} color={"inherit"} variant={"h4"}>ΛΑΚΩΝ</Typography>
          <Box sx={{flexGrow:1}} />
          <Button component={"a"} href={"#contact"} variant={"contained"} color="primary">Παραγκειλε τώρα!</Button>
          <IconButton color={'inherit'} component={"a"} href={"https://www.facebook.com/LakonMelissokomiki"} target={"_blank"}>
            <Facebook />
          </IconButton>
          <IconButton color={'inherit'} component={"a"} href={"mailto:lakonmelissokomiki@gmail.com"} target={"_blank"}>
            <Mail />
          </IconButton>
          <IconButton color={'inherit'} component={"a"} href={"tel:+306948700608"}>
            <Phone />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main>
        <PaperHero />
        <PaperAbout />
        <PaperProducts />
        <PaperContact />
      </main>
      <AppBar color={"primary"} position={"relative"}>
        <Toolbar>
          <Typography sx={{flexGrow:1}} variant={"subtitle1"} align={"center"}>© {new Date().getFullYear()} lakonmelissokomiki.com</Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default App;
